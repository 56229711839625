<template>
  <b-card title="Female Future Leader Timeline List">
    <b-row class="mb-2 justify-content-between">
      <b-col cols="12" lg="3" class="d-flex align-items-center">
        <b-button
          @click="cleanUpForm()"
          v-b-modal.form-ffl-timeline
          variant="primary"
          class="btn waves-effect waves-float waves-light btn-primary"
        >
          Create FFL Timeline
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col cols="12" lg="3">
        <div class="form-group">
          <label for="search">Search:</label>
          <input
            id="search"
            type="text"
            class="form-control"
            placeholder="Search: Name"
            v-model="filter.search"
          />
        </div>
      </b-col>
    </b-row>
    <Table
      :result="result"
      :timeline="timeline"
      :photo_url="photo_url"
      :is-loading="isLoading"
      :get-data="getData"
      :delete-item="deleteItem"
      :formPayload="formPayload"
      :change-photo="changePhoto"
      :edit-item="editItem"
      :update-item="updateItem"
      :create-item="createItem"
      :current-page="currentPage"
      :editId="editId"
      :validations="validations"
    />
  </b-card>
</template>

<script>
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Table from "@/components/female-future-leader-timeline/Table.vue";
import { successNotification, errorNotification } from "@/auth/utils";
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BButton,
  BSpinner,
  VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import _ from "lodash";
export default {
  components: {
    Table,
    BCard,
    BCol,
    BRow,
    BButton,
    BSpinner,
    BFormInput,
    vSelect,
    flatPickr,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData();
      }, 300),
      deep: true,
    },
  },
  data() {
    return {
      currentPage: 1,
      isLoading: false,
      result: [],
      timeline: {},
      filter: {},
      formPayload: {
        title: "",
        date_range: {
          registration_start_date: "",
          registration_end_date: "",
          selection_start_date: "",
          selection_end_date: "",
          announcement_start_date: "",
          announcement_end_date: "",
        },
        order: "",
        thumbnail: "",
      },
      photo_url: null,
      editId: null,
      validations: "",
    };
  },
  setup() {
    return {
      successNotification,
      errorNotification,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    cleanUpForm() {
      this.editId = null;
      this.photo_url = null;
      this.formPayload = {
        title: "",
        date_range: {
          registration_start_date: "",
          registration_end_date: "",
          selection_start_date: "",
          selection_end_date: "",
          announcement_start_date: "",
          announcement_end_date: "",
        },
        order: "",
        thumbnail: "",
      };
    },
    getData(page) {
      this.isLoading = true;
      this.currentPage = page;
      this.filter.page = page;
      this.$http
        .get(`/api/v1/admin/ffl-timelines`, {
          params: this.filter,
        })
        .then((response) => {
          this.result = response.data.data.meta;
          this.timeline = response.data.data.items;
          this.currentPage = response.data.data.meta.currentPage;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    changePhoto(event) {
      this.formPayload.thumbnail = event.target.files[0];
      this.showPhoto(event.target.files[0]);
    },
    showPhoto(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.photo_url = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    preparePayload() {
      const form = new FormData();
      for (const key in this.formPayload) {
        if (this.formPayload.hasOwnProperty(key)) {
          if (key === "date_range") {
            for (const dateKey in this.formPayload[key]) {
              if (this.formPayload[key].hasOwnProperty(dateKey)) {
                form.append(
                  `date_range[${dateKey}]`,
                  this.formPayload[key][dateKey]
                );
              }
            }
          } else {
            form.append(key, this.formPayload[key]);
          }
        }
      }
      return form;
    },
    editItem(item) {
      this.cleanUpForm();
      this.editId = item.uuid;
      this.formPayload = {
        title: item.title,
        date_range: {
          registration_start_date: item.date_range.registration_start_date,
          registration_end_date: item.date_range.registration_end_date,
          selection_start_date: item.date_range.selection_start_date,
          selection_end_date: item.date_range.selection_end_date,
          announcement_start_date: item.date_range.announcement_start_date,
          announcement_end_date: item.date_range.announcement_end_date,
        },
        order: item.order,
        thumbnail: item.thumbnail,
      };
      (this.photo_url = item.thumbnail),
        this.$bvModal.show("form-ffl-timeline");
    },
    createItem() {
      var form = this.preparePayload();
      this.isLoading = true;
      this.$http
        .post("/api/v1/admin/ffl-timelines", form)
        .then((response) => {
          this.$bvModal.hide("form-ffl-timeline");
          this.getData(this.currentPage);
          successNotification(
            this,
            "Success",
            "FFL Timeline successfully created"
          );
          this.cleanUpForm();
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.data.meta.validations) {
            const validations = error.response.data.meta.validations;

            for (const field in validations) {
              if (validations.hasOwnProperty(field)) {
                validations[field] = validations[field].join(", ");
              }
            }

            this.validations = validations;
          } else {
            errorNotification(this, "Error", error.response.data.meta.messages);
          }
          this.isLoading = false;
        });
    },
    updateItem() {
      var form = this.preparePayload();
      this.isLoading = true;
      this.$http
        .post(`/api/v1/admin/ffl-timelines/${this.editId}`, form)
        .then((response) => {
          this.$bvModal.hide("form-ffl-timeline");
          this.getData();
          successNotification(
            this,
            "Success",
            "FFL Timeline successfully updated!"
          );
          this.cleanUpForm();
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.data.meta.validations) {
            const validations = error.response.data.meta.validations;

            for (const field in validations) {
              if (validations.hasOwnProperty(field)) {
                validations[field] = validations[field].join(", ");
              }
            }

            this.validations = validations;
          } else {
            errorNotification(this, "Error", error.response.data.meta.messages);
          }
          this.isLoading = false;
        });
    },
    deleteItem(slug) {
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure to delete this FFL Timeline?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;
          this.$http
            .delete(`/api/v1/admin/ffl-timelines/${slug}`)
            .then((response) => {
              this.getData(this.currentPage);
              this.$swal({
                icon: "success",
                title: "Success!",
                text: "FFL Timeline successfully deleted",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.isLoading = false;
            })
            .catch((error) => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(
                  this,
                  "Oops!",
                  error.response.data.meta.messages
                );
              }
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
