<template>
  <div>
    <b-table
      striped
      hover
      :items="timelineList"
      :fields="fields"
      :busy="isLoadingComponent"
      show-empty
      responsive
    >
      <template #table-busy>
        <div class="text-center text-secondary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template v-slot:cell(no)="{ index }">
        {{ (currentPageList - 1) * 10 + index + 1 }}
      </template>

      <template v-slot:cell(registration_date)="{ item }">
        <div>
          {{ item.date_range.registration_start_date }} to
          <br />
          {{ item.date_range.registration_end_date }}
        </div>
      </template>

      <template v-slot:cell(selection_date)="{ item }">
        <div>
          {{ item.date_range.selection_start_date }} to
          <br />
          {{ item.date_range.selection_end_date }}
        </div>
      </template>

      <template v-slot:cell(announcement_date)="{ item }">
        <div>
          {{ item.date_range.announcement_start_date }} to
          <br />
          {{ item.date_range.announcement_end_date }}
        </div>
      </template>

      <template v-slot:cell(thumbnail)="{ item }">
        <img :src="item.thumbnail" class="image__table" alt="" />
      </template>

      <template v-slot:cell(actions)="{ item }">
        <b-dropdown variant="danger" size="sm">
          <template #button-content> Action </template>
          <b-dropdown-item
            :to="'/detail-female-future-leader-timeline/' + item.uuid"
          >
            <feather-icon icon="AlertCircleIcon" class="mr-75" />
            Detail
          </b-dropdown-item>
          <b-dropdown-item @click="editItem(item)">
            <feather-icon icon="EditIcon" class="mr-75" />
            Update
          </b-dropdown-item>
          <b-dropdown-item @click="deleteItem(item.uuid)">
            <feather-icon icon="TrashIcon" class="mr-75" />
            Delete
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <b-modal
      id="form-ffl-timeline"
      centered
      :title="editIdModal != null ? 'Edit FFL Timeline' : 'Add FFL Timeline'"
      size="lg"
      no-close-on-backdrop
      hide-footer
    >
      <ValidationObserver>
        <div class="form">
          <div class="form-group">
            <label>Title:</label>
            <validation-provider
              name="name"
              rules="required"
              v-slot="{ errors, classes }"
            >
              <input
                type="text"
                class="form-control"
                :class="classes"
                placeholder="Title"
                v-model="formPayloadTimeline.title"
              />
              <small
                v-for="(validation, index) in validations.title"
                :key="`errorName${index}`"
                class="text-danger"
                >{{ validation }}</small
              >
            </validation-provider>
          </div>
          <div class="form-group row">
            <b-col cols="12" lg="6">
              <label for="registrationStartDate"
                >Registration Start Date:</label
              >
              <flat-pickr
                id="registrationStartDate"
                v-model="formPayloadTimeline.date_range.registration_start_date"
                class="form-control"
                :config="{ dateFormat: 'Y-m-d' }"
              />
              <small
                v-for="(validation, index) in validations[
                  'date_range.registration_start_date'
                ]"
                :key="`errorName${index}`"
                class="text-danger"
                >{{ validation }}</small
              >
            </b-col>
            <b-col cols="12" lg="6">
              <label for="registrationEndDate">Registrasion End Date:</label>
              <flat-pickr
                id="registrationEndDate"
                v-model="formPayloadTimeline.date_range.registration_end_date"
                class="form-control"
                :config="{ dateFormat: 'Y-m-d' }"
              />
              <small
                v-for="(validation, index) in validations[
                  'date_range.registration_end_date'
                ]"
                :key="`errorName${index}`"
                class="text-danger"
                >{{ validation }}</small
              >
            </b-col>
            <b-col cols="12" lg="6">
              <label for="selectionStartDate">Selection Start Date:</label>
              <flat-pickr
                id="selectionStartDate"
                v-model="formPayloadTimeline.date_range.selection_start_date"
                class="form-control"
                :config="{ dateFormat: 'Y-m-d' }"
              />
              <small
                v-for="(validation, index) in validations[
                  'date_range.selection_start_date'
                ]"
                :key="`errorName${index}`"
                class="text-danger"
                >{{ validation }}</small
              >
            </b-col>
            <b-col cols="12" lg="6">
              <label for="selectionEndDate">Selection End Date:</label>
              <flat-pickr
                id="selectionEndDate"
                v-model="formPayloadTimeline.date_range.selection_end_date"
                class="form-control"
                :config="{ dateFormat: 'Y-m-d' }"
              />
              <small
                v-for="(validation, index) in validations[
                  'date_range.selection_end_date'
                ]"
                :key="`errorName${index}`"
                class="text-danger"
                >{{ validation }}</small
              >
            </b-col>
            <b-col cols="12" lg="6">
              <label for="announcementStartDate"
                >Announcement Start Date:</label
              >
              <flat-pickr
                id="announcementStartDate"
                v-model="formPayloadTimeline.date_range.announcement_start_date"
                class="form-control"
                :config="{ dateFormat: 'Y-m-d' }"
              />
              <small
                v-for="(validation, index) in validations[
                  'date_range.announcement_start_date'
                ]"
                :key="`errorName${index}`"
                class="text-danger"
                >{{ validation }}</small
              >
            </b-col>
            <b-col cols="12" lg="6">
              <label for="announcementEndDate">Announcement End Date:</label>
              <flat-pickr
                id="announcementEndDate"
                v-model="formPayloadTimeline.date_range.announcement_end_date"
                class="form-control"
                :config="{ dateFormat: 'Y-m-d' }"
              />
              <small
                v-for="(validation, index) in validations[
                  'date_range.announcement_end_date'
                ]"
                :key="`errorName${index}`"
                class="text-danger"
                >{{ validation }}</small
              >
            </b-col>
            <b-col cols="12" lg="6">
              <label for="thumbnail"> Thumbnail:</label>
              <validation-provider
                name="image"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <b-form-file
                  class="mb-2"
                  :class="classes"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  accept="image/jpeg, image/png"
                  @change="changePhoto($event)"
                />
                <small>Current file:</small><br />
                <img
                  v-if="photo_url_data !== null"
                  :src="photo_url_data"
                  class="media_file"
                />
                <small
                  v-for="(validation, index) in validations.thumbnail"
                  :key="`errorName${index}`"
                  class="text-danger"
                  >{{ validation }}</small
                >
              </validation-provider>
            </b-col>
            <b-col cols="12" lg="6">
              <div class="form-group">
                <label>Order:</label>
                <validation-provider
                  name="name"
                  rules="required"
                  v-slot="{ errors, classes }"
                >
                  <input
                    type="number"
                    class="form-control"
                    :class="classes"
                    v-model="formPayloadTimeline.order"
                  />
                  <small
                    v-for="(validation, index) in validations.order"
                    :key="`errorName${index}`"
                    class="text-danger"
                    >{{ validation }}</small
                  >
                </validation-provider>
              </div>
            </b-col>
          </div>
        </div>

        <div v-if="isLoading">
          <br />
          <b-spinner class="mb-2" variant="primary" /><br />
        </div>
        <b-button
          v-if="editIdModal == null"
          variant="primary"
          @click="createItem()"
          v-bind:disabled="isLoading"
        >
          Save FFL Timeline
        </b-button>
        <b-button
          v-else
          variant="primary"
          @click="updateItem()"
          v-bind:disabled="isLoading"
        >
          Edit FFL Timeline
        </b-button>
      </ValidationObserver>
    </b-modal>

    <div
      v-if="resultData.itemsPerPage > 0"
      class="mt-3 d-flex justify-content-between align-items-center"
    >
      <small
        >Showing {{ (resultData.currentPage - 1) * 10 + 1 }} to
        {{ (resultData.currentPage - 1) * 10 + 1 * resultData.itemCount }} from
        {{ resultData.totalItems }}</small
      >
      <b-pagination
        class="justify-content-end"
        v-model="currentPageList"
        :total-rows="resultData.totalItems"
        :per-page="resultData.itemsPerPage"
        aria-controls="my-table"
        @change="getData"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
} from "vee-validate";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
    dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
    // ...
  },
});
import {
  BCol,
  BRow,
  BTable,
  BSpinner,
  BBadge,
  BImg,
  BDropdown,
  BPagination,
  BDropdownItem,
  BButton,
  BFormFile,
  VBModal,
} from "bootstrap-vue";
import _ from "lodash";
export default {
  components: {
    BCol,
    BRow,
    BTable,
    BSpinner,
    BBadge,
    BImg,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    BFormFile,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    timeline: {
      type: "",
    },
    formPayload: {
      type: "",
    },
    validations: {
      type: "",
    },
    editId: {
      type: "",
    },
    result: {
      type: "",
    },
    isLoading: {
      type: Boolean,
    },
    currentPage: {
      type: Number,
    },
    getData: {
      type: Function,
    },
    deleteItem: {
      type: Function,
    },
    editItem: {
      type: Function,
    },
    updateItem: {
      type: Function,
    },
    createItem: {
      type: Function,
    },
    photo_url: {
      type: null,
    },
    changePhoto: {
      type: Function,
    },
  },
  watch: {
    isLoading(value) {
      this.isLoadingComponent = value;
    },
    timeline(value) {
      this.timelineList = value;
    },
    formPayload(value) {
      this.formPayloadTimeline = value;
    },
    validations(value) {
      this.validationsForm = value;
    },
    currentPage(value) {
      this.currentPageList = value;
    },
    result(value) {
      this.resultData = value;
    },
    editId(value) {
      this.editIdModal = value;
    },
    photo_url(value) {
      this.photo_url_data = value;
    },
  },
  data() {
    return {
      required,
      moment,
      isLoadingComponent: false,
      currentPageList: 1,
      resultData: {},
      timelineList: [],
      editIdModal: null,
      formPayloadTimeline: {
        title: "",
        date_range: {
          registration_start_date: "",
          registration_end_date: "",
          selection_start_date: "",
          selection_end_date: "",
          announcement_start_date: "",
          announcement_end_date: "",
        },
        thumbnail: "",
        order: "",
      },
      photo_url_data: null,
      validationsForm: "",
      fields: [
        { key: "no" },
        { key: "thumbnail" },
        { key: "title" },
        { key: "registration_date" },
        { key: "selection_date" },
        { key: "announcement_date" },
        { key: "order" },
        { key: "actions" },
      ],
    };
  },
  computed: {
    rows() {
      return this.timelineList.length;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.image__table {
  width: 200px;
  height: 100px;
  object-fit: contain;
}
.media_file {
  width: 400px;
  height: 300px;
  object-fit: contain;
}
</style>
